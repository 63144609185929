<template>
    <div class="construction-sit-child-table">
        <div class="child-one">
            <el-table
                :data="tableData"
                border
                show-summary
            >
                <el-table-column
                    prop="name"
                    label="施工部位"
                >
                </el-table-column>
                <el-table-column
                    prop="yyfl"
                    label="已用方量(方)"
                >
                </el-table-column>
                <el-table-column
                    prop="ddsl"
                    label="订单数(个)"
                >
                </el-table-column>
                <el-table-column
                    prop="fhdsl"
                    label="发货单数量(个)"
                >
                </el-table-column>
            </el-table>
        </div>
        <div class="child-two">
            <h1>已用方量</h1>
            <div style="width:100%;height:450px" id="usedQuantityId"></div>
        </div>
    </div>
</template>

<script>
// import addBgImages from '../../utils';
// 按施工部位
export default {
    data() {
        return {
            tableData: [],
            echartsName: [],
            echartsDdsl: [],
            echartsFhdsl: [],
            echartsYyfl: [],
            selectTimeData: ['2020-01-01', '2020-06-20'],
        };
    },
    // props: {
    //     selectTimeData: {
    //         type: Array,
    //     },
    // },
    mounted() {
        this.salesanalysisSgbw();
    },
    methods: {
        usedQuantityFun() {
            const myChart = this.$echarts.init(document.getElementById('usedQuantityId'));
            const option = {
                grid: {
                    top: '5%',
                    left: '3%',
                    bottom: '3%',
                    containLabel: true,
                },
                xAxis: {
                    type: 'category',
                    data: this.echartsName,
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: { show: false },
                },
                yAxis: {
                    name: '单位(方)',
                    nameTextStyle: {
                        padding: [0, 0, -20, 45], // 四个数字分别为上右下左与原位置距离
                        color: '#979EA7',
                        fontSize: '10',
                    },
                    type: 'value',
                    axisLabel: {
                        color: '#666666',
                        fontSize: 10,
                    },
                    axisTick: {
                        show: false,
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                        },
                    },
                    splitLine: {
                        lineStyle: {
                            color: '#D7D7D7',
                            type: 'dashed',
                        },
                    },
                },
                series: [{
                    data: this.echartsYyfl,
                    type: 'bar',
                    barWidth: 20,
                    label: {
                        show: true,
                        position: 'top',
                        fontFamily: 'QuartzLTStd',
                        color: '#44A1F5',
                        padding: 5,
                        // backgroundColor: {
                        //     image: addBgImages(1, 'top'),
                        // },
                    },
                    showBackground: true,
                    itemStyle: {
                        normal: {
                            color: new this.$echarts.graphic.LinearGradient(0, 1, 0, 0, [{
                                offset: 0,
                                color: '#3E78C3', // 0% 处的颜色
                            }, {
                                offset: 1,
                                color: '#44A1F5', // 100% 处的颜色
                            }], false),
                        },
                    },
                    backgroundStyle: {
                        color: 'rgba(220, 220, 220, 0.8)',
                    },
                }],

            };
            myChart.setOption(option, true);
        },
        // 按施工部位
        salesanalysisSgbw() {
            this.$axios
                .get(`/interfaceApi/report/salesanalysis/gcyl/sgbw?startDate=${this.selectTimeData[0]}&endDate=${this.selectTimeData[1]}`)
                .then(res => {
                    this.tableData = res.list;
                    this.tableData.forEach(item => {
                        this.echartsName.push(item.name);
                        this.echartsDdsl.push(item.gcsl);
                        this.echartsFhdsl.push(item.yjfl);
                        this.echartsYyfl.push(item.yyfl);
                    });
                    this.usedQuantityFun();
                })
                .catch(err => {
                    console.log(err);
                });
        },
    },
};

</script>
<style lang='stylus'>
.construction-sit-child-table
    padding 0 .2rem .2rem .2rem
    display flex
    .child-one
        flex 1
        .el-table
            thead tr th
                background #EDF0F5
                color #022782
                font-weight 800
            td, th
                padding 0
                text-align center
                font-size .16rem
                div
                    height .4rem
                    line-height .4rem
        .el-table__footer tr td
            background none
            color #C9661E
            &:first-child
                background #F5F2ED
                color #823802
    .child-two
        flex 2
        margin-left .2rem
        border 1px solid #D7D7D7
        padding .2rem
        h1
            color #333333
            font-size .18rem
            font-weight 800
            &:before
                content '`'
                width .06rem
                height .2rem
                background #4D7DE0
                color #4D7DE0
                margin-right .1rem
</style>